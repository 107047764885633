import React from 'react'
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


function Home() {

  if(localStorage.getItem("token")) {
    return (
   
      <div className='App-header'>
       
          <h3>My Laravel React Basic Auth Example</h3>
          
          
      </div>
  )
}else {
  return (
   
      <div className='App-header'>
       
          <h3>My Laravel React Basic Auth Example</h3>
          <Button variant="primary" ><Link className="text-white text-decoration-none" to="/login">Sign In or Register </Link></Button>
         {/* <div className='col-4 mx-auto'><button type="submit" className="btn btn-secondary btn-lg btn-block">Sign in</button></div>  */}
          
      </div>
  )
}}

export default Home