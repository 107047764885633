import React from 'react';
import { Navigate } from 'react-router-dom';



function Profile({user}) {

  console.log(user)
  return ( 
    user?
    <div className="outer">
    
    
    <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">


<h3 className="center">User Profile</h3>

<ul class="list-group mb-5">
  <li class="list-group-item">Name: {user.name}</li>
  <li class="list-group-item">Email: {user.email}</li>
  
</ul>

</div>
</div>
:<Navigate to={'/login'}/>

  
    
  
  )
}

export default Profile