import React, { useEffect } from 'react'
import { useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import axios from 'axios'; 
import ClipLoader from "react-spinners/ClipLoader";


function Login({setUser, user}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn]= useState(false);
  
    const [message, setMessage] = useState("");
    const [loading, setLoading]= useState(true);


//method submit

const formSubmit=(e)=>{
    e.preventDefault();
    axios.post("/login", {
        
        email:email,
        password:password,
       }).then((response)=>{
        localStorage.setItem('token',response.data.token);
        // thisUser(response.data);
        // setLoggedIn(true);
        console.log(response.data)
        setUser(response.data.user)
        // setStorage(response.data.token);
          ;}).then().catch((error) => {
          // here you will have access to error.response,
          console.log(error.response.data.message)
          setMessage(error.response.data.message)
      });

};

let error="";
  
if(message){

  error= (<div>
    <div className='alert alert-danger' role="alert">
    {message}
    </div>
  </div>)
} 

// If you want to use loader please uncomment

// useEffect(()=>{
    
//     const timer = setTimeout(() =>  setLoading(false), 3400);
//     return () => clearTimeout(timer);

   
//       },[])

// if(loading){return(<div className="outer text-center mx-auto">
      
      
// <div className="test text-primary"><ClipLoader color={"#0d6efd "}/></div></div>)}else{ 

if(localStorage.getItem("token")) {
   return <Navigate to={'/profile'}/>
}else{
    return (
  
  
  
    <div>
  
  
  
  
    
    <div className="outer">
      
      
      <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
  <form onSubmit={formSubmit}>
  
  <h3 className="center">Log in</h3>
  {error}

  <div className="form-group">
      <label>Email</label>
      <input type="email" className="form-control" name="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
  </div>
  
  <div className="form-group">
      <label>Password</label>
      <input type="password" className="form-control" name="password"  onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
  </div>
  
  <div className="form-group">
      <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="customCheck1" />
          <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
      </div>
  </div>
  
  <button type="submit" className="btn btn-dark btn-lg btn-block">Sign in</button>
  <p className="forgot-password text-right">
      Forgot <Link to="/forget">password?</Link>
  </p>
  </form>
  </div>
  </div>
  
  
  </div>
  
    
      
    )
// }
    

// if(loading){return<p>loading</p>}else{<div>finished loading</div>


//     {user? <Navigate to={'/profile'}/>:
  
    
//     <div className="outer">
      
      
//       <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
//   <form onSubmit={formSubmit}>
  
//   <h3 className="center">Log in</h3>
  
//   <div className="form-group">
//       <label>Email</label>
//       <input type="email" className="form-control" name="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
//   </div>
  
//   <div className="form-group">
//       <label>Password</label>
//       <input type="password" className="form-control" name="password"  onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
//   </div>
  
//   <div className="form-group">
//       <div className="custom-control custom-checkbox">
//           <input type="checkbox" className="custom-control-input" id="customCheck1" />
//           <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
//       </div>
//   </div>
  
//   <button type="submit" className="btn btn-dark btn-lg btn-block">Sign in</button>
//   <p className="forgot-password text-right">
//       Forgot <Link to="/forget">password?</Link>
//   </p>
//   </form>
//   </div>
//   </div>
  
//   }
//   </div>}


//   return (



//   <div>


//   {user? <Navigate to={'/profile'}/>:

  
//   <div className="outer">
    
    
//     <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
// <form onSubmit={formSubmit}>

// <h3 className="center">Log in</h3>

// <div className="form-group">
//     <label>Email</label>
//     <input type="email" className="form-control" name="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
// </div>

// <div className="form-group">
//     <label>Password</label>
//     <input type="password" className="form-control" name="password"  onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
// </div>

// <div className="form-group">
//     <div className="custom-control custom-checkbox">
//         <input type="checkbox" className="custom-control-input" id="customCheck1" />
//         <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
//     </div>
// </div>

// <button type="submit" className="btn btn-dark btn-lg btn-block">Sign in</button>
// <p className="forgot-password text-right">
//     Forgot <Link to="/forget">password?</Link>
// </p>
// </form>
// </div>
// </div>

// }
// </div>

  
    
//   )
}
}
export default Login