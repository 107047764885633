import React, { useState, useEffect } from 'react';
import Nav from './Nav';
import Login from './Login';
import Home from './Home';
import Register from './Register';
import Reset from './reset';
import LoginContainer from './LoginContainer';
import axios from 'axios';
import '../App.css'
import {
 
  RouterProvider,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Forget from './Forget';
import Profile from './Profile';

const Header = () => {
const [user, setUser]= useState('');
const [loading, setLoading]= useState(true);

const getUser=()=>{

  axios.get("/user").then((response)=>{
  
    thisUser(response.data);
   
    console.log(response.data);
      ;}).catch((error) => {
      // here you will have access to error.response
      console.log(error.response)
  });
}

const thisUser=(arg)=>{
setUser(arg);
}

  useEffect(()=>{
    
    getUser();
      },[])
    

  return (
<div>
    <Nav user={user} setUser={setUser}/>
   
      
    
    <Routes>
          
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/login" element={<Login user={user} setUser={setUser}/>}/>
          <Route exact path="/register" element={<Register loading={loading}  getUser={getUser} user={user} setUser={setUser}/>}/>
          <Route exact path="/forget" element={<Forget/>}/>
          <Route exact path="/profile" element={<Profile user={user}/>}/>
          <Route exact path="/reset/:id" element={<Reset user={user}/>}/>
          {/* <Route exact path="/logincontainer" element={<LoginContainer setUser={setUser} user={user}/>}/> */}
        </Routes>
  
    
    </div>)
}

export default Header