import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


function Register({setUser, user, getUser}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword]= useState("");
    const [loggedIn, setLoggedIn]= useState(false);
  
    const [message, setMessage] = useState(true);
  

    const formSubmit=(e)=>{
        e.preventDefault();
        axios.post("/register", {
            name:name,
            email:email,
            password:password,
            password_confirmation:confirmPassword,
           }).then((response)=>{
            localStorage.setItem('token',response.data.token);
            // thisUser(response.data);
          
            console.log(response.data)
            setUser(response.data.user)
            // setStorage(response.data.token);
              ;}).catch((error) => {
              // here you will have access to error.response,
              console.log(error.response)
          });
    
    };

    // useEffect(()=>{
    
    //     getUser();
       
    //       },[]);


         


          if(localStorage.getItem("token")) {
            return <Navigate to={'/profile'}/>
        }else{

  return (
    <div className="outer">
    
    
    <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
<form onSubmit={formSubmit}>

<h3 className="center">Register</h3>

<div className="form-group">
    <label>User Name</label>
    <input type="text" name="name" className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Enter name" required />
</div>

<div className="form-group">
    <label>Email</label>
    <input type="email" name="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
</div>

<div className="form-group">
    <label>Password</label>
    <input type="password" name="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
</div>

<div className="form-group">
    <label>Confirm Password</label>
    <input type="password" name="password_confirmation" className="form-control" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Enter password" required/>
</div>
<div><p></p></div>

<button type="submit" className="btn btn-dark btn-lg btn-block">Register</button>
<p className="forgot-password text-right">
    Have an account? <Link to="/login">Log In</Link>
</p>
</form>
</div>
</div>

  
  )}
}

export default Register