import axios from 'axios';
import React, { useState } from 'react'
import {Link} from 'react-router-dom';


const Forget = () => {

  const [email, setEmail]= useState("");
  const [message, setMessage]= useState("");


 const formSubmit=(e)=>{
    e.preventDefault();
    const data= {
      email: email,
       }

    axios.post("/forgetpassword", data)
    .then((response)=>{
      // console.log(response.data.message)
      setMessage(response.data.message)
     
      document.getElementById("forgetForm").reset();
    })
    .catch((error)=>{
      // console.log(error.response.data.message)
      setMessage(error.response.data.message)
      
    })

  }

  let error="";
  
  if(message){

    error= (<div>
      <div className='alert alert-danger' role="alert">
      {message}
      </div>
    </div>)
  } 
  // else if(!message&&success){

  //   error= (<div>
  //     <div className='alert alert-danger' role="alert">
  //     {message}
  //     </div>
  //   </div>)
  // }
  
  return (
    
    <div className="outer">
    
    
    <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
<form onSubmit={formSubmit} id="forgetForm">

<h3 className="center">Forgot Password?</h3>
{error}


<div className="form-group">
    <label>Email</label>
    <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" />
</div>


<div><p></p></div>

<button type="submit" className="btn btn-dark btn-lg btn-block">Submit</button>
<p className="forgot-password text-right">
  
    Do not have an account? <Link to="/register">Register</Link>
</p>
</form>
</div>
</div>

  )
}

export default Forget