import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

//Set base URL
// axios.defaults.baseURL = "http://127.0.0.1:8000/api"
axios.defaults.baseURL = "https://react-laravelauth.oncode.uk/admin/public/api"

const root = ReactDOM.createRoot(document.getElementById('root'));
axios.defaults.headers.common['Authorization']= 'Bearer '+localStorage.getItem('token');
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
